<template>
    <v-card class="info-card pa-3" elevation="0" rounded="lg">
        <v-row align-content="center" justify="start" class="d-flex flex-row flex-nowrap align-top justify-center px-4">
            <!-- <v-chip label color="deep-purple lighten-4" class="icon-max mr-4 mt-4 px-4 d-flex align-center jusitfy-center text-center" large>
                <v-icon color="primary" class="mr-15">
                    {{ icon }}
                </v-icon>
            </v-chip> -->

            <p class="body-3 font-weight-regular my-3" v-html="text"></p>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: 'AlertBannerCard',
    props: {
        icon: {
            type: String,
            default: 'mdi-alert-circle',
            required: false
        },
        color: {
            type: String,
            default: 'primary',
            required: false
        },
        text: {
            type: String,
            default: 'Click here!',
            required: true
        }
    }
}
</script>
<style lang="scss">
.icon-max{
    min-width: 55px;
    max-width: 55px;
}
.v-card.info-card{
    background: #D7D2EF33;
    font-size: .85rem;
}
.evaluation-info{
    p{
        line-height: 140%;
        margin-bottom: 2px;
    }
    b, strong{
        font-weight: 600;
    }
    h4{
        border-radius: 8px 8px 0 0;
        font-weight: 600;
        padding: 6px 12px;
    }
}
.evaluation-wrapper{
    color: #514585;
    h4{
        background: #D7D2EF;
    }
    .evaluation-desc{
        background: #EEECF8;
        border-radius: 0 0 8px 8px;
        padding: 12px;
    }
}
.evaluation-important{
    h4{
        background: #514585;
        color: white;
        i{
            color: #D7D2EF;
            vertical-align: text-top;
        }
    }
    .important-desc{
        background: #EEECF8;
        color: #514585;
        border-radius: 0 0 8px 8px;
        padding: 12px;
    }
}
.material-icons.md-18 { font-size: 18px; }
</style>